@import "../../shared";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding-top: $spacing-xxl;
  padding-bottom: $spacing-xxl;
  margin-left: $spacing-md;
  margin-right: $spacing-md;
}

.box {
  display: flex;
  flex-direction: column;

  padding: $spacing-xxl;
  box-shadow: $shadow-secondary;
  border-radius: $spacing-lg;
}

.logo {
  align-self: center;
}

.title {
  font-size: $font-size-title;
  color: $color-gray-dark;
  margin-top: $spacing-xxl;
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: $spacing-lg;
}
